<!-- 订单详情 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">订单详情</div>
    </header>
    <section class="page-info">
      <div class="delivery-info" @click="handleChooseAddress">
        <div class="click-tag">点击切换地址</div>
        <div v-if="!isEmptyObject(addressInfo)">
          <div class="delivery-title">配送信息：</div>
          <div class="delivery-content">
            {{ addressInfo.province }} {{ addressInfo.city }} {{ addressInfo.county }}
            {{ addressInfo.address }}
          </div>
          <div class="delivery-content">
            {{ addressInfo.organ_name }} {{ addressInfo.branch_name }} 病房:{{
              addressInfo.room_number
            }}
            病床:{{ addressInfo.bed_number }}
          </div>
          <div class="delivery-people">
            {{ addressInfo.consignee }} {{ addressInfo.phone_num }}
          </div>
        </div>
        <div v-else class="no-address-info">请选择配送地址</div>
      </div>
      <div class="bill-creater-info">
        <div class="bill-time">订餐时间：{{ creat_dt }}</div>
        <div style="color: #ff3333">￥{{ shopCartTotal }}</div>
        <div>就餐日期：{{ order_dt }}</div>
        <div>订餐方式：微信</div>
      </div>
      <div class="bill-detail">
        <div v-for="(item, index) in shopCartList" :key="index" class="bill-item">
          <div class="bish-detail">
            <van-row gutter="10">
              <van-col span="15">
                <div class="meal-name">
                  {{ item.meal_name }}
                </div>
              </van-col>
              <van-col span="3">
                <div class="dish-num">x{{ item.meal_num }}</div>
              </van-col>
              <van-col span="6">
                <div class="dish-price">￥{{ item.meal_num * item.member_price }}</div>
              </van-col>
            </van-row>
            <div
              v-for="(modeItem, medeIndex) in item.mode_list"
              :key="medeIndex"
              class="mode-item"
            >
              <div class="mode-name">{{ modeItem.mode_name }}</div>
              <div class="dishes-main">
                <span
                  v-for="(dishesItem, dishes_index) in modeItem.dishes_list"
                  :key="dishes_index"
                  >{{
                    dishes_index == 0
                      ? dishesItem.dishes_name
                      : "、" + dishesItem.dishes_name
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="summit">
        <van-submit-bar
          :price="shopCartTotal * 100"
          button-text="提交订单"
          :loading="submitLoading"
          @submit="onSubmit"
        >
          <template
            v-if="!isEmptyObject(addressInfo) && addressInfo.organ_id != organ_id"
            #tip
          >
            你的收货地址不支持配送, <span @click="handleChooseAddress">修改地址</span>
          </template>
        </van-submit-bar>
      </div>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "vant";

import shopCart from "@/api/ofs/shopCart";
import order from "@/api/ofs/order";
import mywx from "@/api/ofs/wx";
import address from "@/api/ofs/address";

// import { getSession } from "@/utils/sessionUtils"
import { getStorage } from "@/utils/localStorageUtils";
import CommonData from "@/utils/commonData";

import { getCurrentTime, getCountTime, isEmptyObject } from "@/utils/util";

import wxPay from "@/utils/wxPay";

export default {
  name: "doMealOrder",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options);
      if (options != null) {
        if (options.order_type != null) {
          data.order_type = options.order_type;
        }
        if (options.shop_type != null) {
          data.shop_type = options.shop_type;
        }
      }

      if (data.order_type == 2) {
        data.order_dt = getCountTime(-1);
      }

      data.organ_id = getStorage(CommonData.KeyStorages.KEY_ORGAN_ID);
      getShopCartList();
      getAddressInfo();
    });

    const getAddressInfo = () => {
      var add_id = getStorage(CommonData.KeyStorages.KEY_ADD_ID);
      if (add_id != null) {
        const post_data = {
          add_id: add_id,
        };
        address.getAddressInfo(post_data).then((res) => {
          data.addressInfo = res.data;
        });
      } else {
        address.getDefaultAddress({}).then((res) => {
          data.addressInfo = res.data;
        });
      }
    };

    const getShopCartList = () => {
      var totalPrice = 0;
      var shopCartList = [];
      const post_data = {
        shop_type: data.shop_type,
        order_type: data.order_type,
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
      };
      shopCart.getShopCartList(post_data).then((res) => {
        if (res.data != null && res.data.length > 0) {
          res.data.map((item) => {
            totalPrice += item.dishes_num * parseFloat(item.member_price);
            item.member_price = parseFloat(item.member_price).toFixed(2);
            shopCartList.push(item);
          });
        }
        data.shopCartList = shopCartList;
        data.shopCartTotal = totalPrice.toFixed(2);
      });
    };

    const handleChooseAddress = () => {
      const params = {};
      if (!isEmptyObject(data.addressInfo)) {
        params.add_id = data.addressInfo.add_id;
      }
      $router.push({
        name: "addressMgr",
        query: params,
      });
    };

    const onSubmit = () => {
      console.log("提交订单!");
      if (isEmptyObject(data.addressInfo)) {
        Toast({ type: "fail", message: "配送地址为空，无法下单!" });
        return;
      }
      if (data.shopCartList == null || data.shopCartList.length == 0) {
        Toast({ type: "fail", message: "商品数为空，无法下单!" });
        return;
      }
      Toast.loading({
        message: "订单提交中...",
        forbidClick: true,
        duration: 0,
      });
      data.submitLoading = true;
      const post_data = {
        add_id: data.addressInfo.add_id,
        shop_type: data.shop_type,
        order_type: data.order_type,
        organ_id: data.organ_id,
        dishes_list: JSON.stringify(data.shopCartList),
        canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
      };
      var out_trade_type = data.shop_type == 2 ? 104 : 101;
      console.log(post_data);
      order
        .creatOrder(post_data)
        .then((res) => {
          const post_data = {
            out_trade_sn: res.data.pay_sn,
            out_trade_type: out_trade_type,
            description: "线上支付",
            canteen_id: getStorage(CommonData.KeyStorages.KEY_CANTEEN_ID),
          };
          mywx
            .payOfsOrder(post_data)
            .then((res) => {
              if (res.code == 0) {
                // const pay_params = res.data;
                // wexinPay(pay_params);
                window.location.href = res.data.pay_url;
              } else {
                proxy.$toast({ type: "fail", message: res.message });
              }
            })
            .catch((err) => {
              Toast.clear();
              data.submitLoading = false;
            });
          // Toast.clear()
        })
        .catch((err) => {
          // Toast.clear()
          data.submitLoading = false;
        });
    };

    //调起支付
    const wexinPay = (params) => {
      wxPay(params)
        .then((res) => {
          Toast({ type: "success", message: "支付成功" });
          data.submitLoading = false;
          $router.replace({
            name: "bill",
          });
        })
        .catch((err) => {
          Toast({ type: "fail", message: "支付失败，请重试" });
          $router.replace({
            name: "bill",
          });
          data.submitLoading = false;
        });
    };

    const data = reactive({
      submitLoading: false,
      shopCartTotal: 0,
      shopCartList: [],
      addressInfo: {},
      organ_id: 0,
      shop_type: 1,
      order_type: 1,
      creat_dt: getCurrentTime(),
      order_dt: getCountTime(0),
    });

    return {
      ...toRefs(data),
      handleChooseAddress,
      onSubmit,
      isEmptyObject,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }

    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }

  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin: 20px;

    .delivery-info {
      background: #fafafa;
      border-radius: 12px;
      font-size: 14px;
      line-height: 25px;
      padding: 10px;
      color: #666666;
      position: relative;

      .click-tag {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        padding: 3px 10px;
        color: #fff;
        background: #9f88ff;
        border-radius: 0 12px 0 12px;
      }

      .delivery-title {
        color: #ff3333;
      }

      .delivery-content {
        color: #000000;
        line-height: 20px;
        font-weight: bold;
      }

      .delivery-people {
        font-size: 12px;
      }

      .no-address-info {
        height: 100%;
        color: #ff3333;
        min-height: 60px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-weight: bold;
      }
    }

    .bill-creater-info {
      margin: 20px 0;
      border-radius: 20px;
      background: #fafafa;
      padding: 10px;
      font-size: 14px;
      line-height: 24px;
      color: #aaaaaa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .bill-detail {
      padding-bottom: 70px;
      .bill-item {
        border-top: 1px solid #e5e5e5;
      }
      .meal-type {
        font-size: 16px;
        line-height: 20px;
        color: #666666;
        margin: 10px 0;
      }

      .bish-detail {
        margin: 10px 0;
        line-height: 16px;
        color: #aaaaaa;

        .mode-item {
          padding-top: 10px;
          line-height: 20px;
          .mode-name {
            color: #42a5f5;
            font-size: 16px;
          }
          .dishes-main {
            font-size: 14px;
            color: #666666;
          }
        }
        .meal-name {
          font-size: 16px;
          color: #000000;
        }

        .dish-num {
          text-align: center;
        }

        .dish-price {
          color: #ff3333;
          text-align: center;
        }
      }

      .bill-evaluate {
        margin: 20px 0;
        width: 100%;
        text-align: right;
      }
    }
  }
}
</style>
